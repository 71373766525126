// Components/Loader.js
import React from 'react';
import './Loader.css'; // Import CSS for loader styles

const Loader = () => {
  return (
    <div class="container-loader">
      <div class="loader">
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__bar"></div>
        <div class="loader__ball"></div>
      </div>
    </div>
  );
};

export default Loader;
