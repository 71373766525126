import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import Loader from "./components/Loader/Loader";
import AOS from "aos";
import "aos/dist/aos.css";

// Lazy load the Home component
const Home = React.lazy(() => import("./pages/Home"));

function App() {
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    AOS.init({
      duration: 1600, // Animation duration
      once: true, // Whether animation should happen only once
    });
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after a timeout
    }, 1600); // Adjust the duration as needed (1500ms = 1.5 seconds)
    return () => clearTimeout(timer); // Cleanup the timeout on unmount
  }, []);

  return (
    <div>
      <Router>
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <AnimatedCursor
                innerSize={10}
                outerSize={15}
                color="40, 10, 74"
                outerAlpha={0.5}
                innerScale={1.0}
                outerScale={3}
              />
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                </Routes>
              </Suspense>
            </>
          )}
        </>
      </Router>
    </div>
  );
}

export default App;
